export const sync = `mutation Sync($Id: ID!, $TrainingType: String!, $ForceSync: Boolean, $LastSyncDate: String, $Ciid: String){
  sync(Id: $Id, TrainingType: $TrainingType, ForceSync: $ForceSync, LastSyncDate: $LastSyncDate, Ciid: $Ciid)
}`

export const upsertOrganization = `mutation UpsertOrganization($organization: OrganizationInput) {
  upsertOrganization(organization: $organization)
}`


export const upsertProvider = `mutation UpsertProvider($provider: ProviderForUpsert!) {
  upsertProvider(provider: $provider) {
    Id
    Type
    Name
    AuthenticationUrl
    CourseSyncUrl
    SyncTime
    SyncTimeList
    LastSuccessfulModifiedDateCourse
    Scope
    Audience
    AuthenticationType
    Abbreviation
    IsExcelProvider
    LastDateNotified
    IsMobileSupported
    IsFrequentlySync
    LoginUrl
    LaunchUrl
    LTIClientId
    PublicKey
    FreeFormParams{
      Key
      Value
    }
    IsActive
    IsStrictSchema
    ProviderOfferingType
  }
}`

export const upsertSettings = `mutation UpsertSettings($setting: ProviderToolDataInput!) {
  upsertSettings(setting: $setting) {
    Id
    IsCustomSubjectMapping
    IsCustomCourseMapping
    DirectSubscriptionCIIDs
    IsForCuration
    TestPortals {
      IsEnabled
      Url
      Ciid
    }
    Notes
  }
}`

export const subscribeGQCall =`mutation Subscribe($subscription: SubscriptionInput) {
  subscribe(subscription: $subscription)
}`

export const directSubscribeQGCall = `mutation DirectSubscribe($directSubscription: DirectSubscriptionInput) {
  directSubscribe(directSubscription: $directSubscription)
}`
  
export const addProvider = `mutation AddProvider($bundleProvider: BundleProviderParameter) {
  addProvider(bundleProvider: $bundleProvider)
}`