import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import OrganizationStore from '../../stores/OrganizationStore';
import DropdownComponent from '../common/inputs/DropdownComponent';
import { AuthenticationType, authTypeToString, stringToAuthType } from '../../models/ProviderDetails/AuthenticationType';
import SwitcherComponent from '../common/inputs/SwitcherComponent';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import CreatableSelect from 'react-select/creatable';
import { v4 } from 'uuid';
import Select from "react-select";
import LabelComponent from '../common/inputs/LabelComponent';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import "./FrameworkComponent.css";
import OrganizationDetailsErrorModel from '../../models/Organizations/OrganizationErrorModel';
import FrameworkHelpers from '../../helpers/framework/FrameworkHelpers';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import { ChangeEvent } from 'react';
import OrganizationDetailsConstants from '../../constants/OrganizationConstants';
import { IntegrationType, EnvironmentType, SsoType, integrationTypeToString, stringToSsoType, stringToIntegrationType, envTypeToString, stringToUserIdentifierType, userIdentifierTypeToString, UserIdentifierType, ssoTypeToString, AuthenticationObject, SSOObject, XAPIProcessObject, stringToEnvType, XapiFreeformType, KeyValueInput, KeyTypeValueInput } from '../../models/Organizations/OrganizationModel';
import { Tooltip, IconButton ,withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LargeTextboxComponent from '../common/inputs/LargeTextboxComponent';
import learningPlatofrmList from  '../framework/OrganizationLearningPlatforms.json'
import corpNameDetails from './OrganizationCorpNames.json';

interface OrganizationDetailsProps {
    detailsErrorMessages?: OrganizationDetailsErrorModel,
    organizations?: OrganizationStore,
    isScrolledToProblemElement: boolean,
    onScrolledToProblemElement: () => void,
}
const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f58662',
      color: '#fff',
      fontSize: 15,
      borderRadius: 5,
      padding: '5px 10px',
    },
    arrow: {
      color: '#f58662',
    },
  }))(Tooltip);
interface OrganizationDetailsState {
    authType?: AuthenticationType,
    integrationType?: IntegrationType,
    envType?: EnvironmentType,
    ssoType?: SsoType,
    isSpEncrypted: boolean

}

@inject('organizations')
@observer
export default class OrganizationDetails extends Component<OrganizationDetailsProps, OrganizationDetailsState> {
    deepLinkSelectedDisable: boolean = false;
     languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
     regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
     scriptNames = new Intl.DisplayNames(['en'], { type: 'script' });
    constructor(props: OrganizationDetailsProps) {
        super(props);
        this.initialSetup(props);

        this.state = {
            authType: props.organizations?.CurrentOrganization?.Authentication?.AuthType,
            ssoType: props.organizations?.CurrentOrganization?.Sso?.Type,
            integrationType: props.organizations?.CurrentOrganization?.IntegrationType,
            envType: props.organizations?.CurrentOrganization?.Environment,
            isSpEncrypted: props.organizations?.CurrentOrganization?.Sso?.IsSpEncrypted ?? false
        }
        if (this.props.organizations?.CurrentOrganization?.IntegrationType == IntegrationType.Deeplink)
            this.deepLinkSelectedDisable = true;
    }

    initialSetup = (props) => {
        props.organizations.CurrentOrganization.Authentication = props.organizations.CurrentOrganization.Authentication ?? new AuthenticationObject();
        props.organizations!.CurrentOrganization!.Authentication!.AuthType = AuthenticationType.Basic;
        props.organizations!.CurrentOrganization!.Authentication!.Username = props.organizations!.CurrentOrganization!.Authentication!.Username || props.organizations.CurrentOrganization.Id;
        props.organizations!.CurrentOrganization!.Authentication!.Password = props.organizations!.CurrentOrganization!.Authentication!.Password || crypto.randomUUID();
        if (props.organizations.CurrentOrganization.IsActive == undefined)
            props.organizations.CurrentOrganization.IsActive = true;

        if (props.organizations.CurrentOrganization.IsExternal == undefined)
            props.organizations.CurrentOrganization.IsExternal = true;
    }
    componentDidUpdate = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement, this.props.onScrolledToProblemElement);
    }

    componentDidMount = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement, this.props.onScrolledToProblemElement);
    }

    formatLanguageTag(languageTag:string) {
        let subtags = languageTag.split('-');
        try{
            let languageCode = subtags[0];
            let scriptCode = null;
            let regionCode = null;
        
            if (subtags.length === 2) {
                // If the second subtag is a script (4 letters) or a region (2 letters or digits)
                if (subtags[1].length === 4) { 
                    scriptCode = subtags[1];
                } else {
                    regionCode = subtags[1];
                }
            } else if (subtags.length === 3) { 
                scriptCode = subtags[1];
                regionCode = subtags[2];
            }
        
            let language = this.languageNames.of(languageCode);
            let script = scriptCode ? this.scriptNames.of(scriptCode) : '';
            let region = regionCode ? this.regionNames.of(regionCode) : '';
        
            return `${language}${script ? ' (' + script + ')' : ''}${region ? ' (' + region + ')' : ''}`;
        }
        catch{
            return languageTag;
        }
    }
    render() {
        var corpSettingsCount = 0;        
        if(!this.props.organizations!.CurrentOrganization!.OrgSettingCollection) 
        {
            this.props.organizations!.CurrentOrganization!.OrgSettingCollection= [];
        }
        return <div className={"csod-framework-tab-content"}>
            <div className="csod-framework-layer">
                <div className="csod-framework-header non-selectable">{OrganizationDetailsConstants.GENERAL_INFORMATION_HEADER}</div>
                <div className="csod-inputs-row-container two-columns-container">
                    <TextInputComponent title="Organization Name" important={true} widthClass={InputWidthTypes.Middle}
                        defaultValue={this.props.organizations?.CurrentOrganization?.Name}
                        errorMessage={this.props.detailsErrorMessages?.nameErrorMessage} maxlength={500} onValueChanged={this.onOrganizationNameChanged} />
                    <LabelComponent title="ID" value={this.props.organizations?.CurrentOrganization?.Id != null ? this.props.organizations.CurrentOrganization.Id : "N/A"}
                        isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
                </div>

                <div className="csod-inputs-row-container two-columns-container">
                    <div className='csod-inputs-column-container middle-width'>
                            <div>
                                <div style={{ display: 'flex'}}>
                                   <div className="csod-input-title-text non-selectable">Select Subscriptions *</div>
                                   <CustomTooltip arrow title="3rd party learning platforms like Workday are only eligible for Curated (CCA) Subscriptions, not Partner (ALC) or Pick N subscriptions." >
                                        <IconButton size="small" style={{top:'-3px'}}>
                                            <InfoIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                                <Select placeholder="Select Subscriptions" myFontSize="20px" isMulti={true}
                                    important={true} options={this.props.organizations?.Bundles.map(x => ({ label: x.Name, value: x.Id })).sort((a, b) => a.label.localeCompare(b.label))}
                                    defaultValue={this.props.organizations?.Bundles.filter(x =>  this.props.organizations?.CurrentOrganization?.OrgBundle?.filter(x=>x.IsActive).map(x => x.Id).includes(x.Id)).map(x => ({ label: x.Name, value: x.Id })).sort((a, b) => a.label.localeCompare(b.label))}
                                    onChange={this.onCcaBundleChanged} />
                                {!CodeHelpers.IsStringNullOrEmpty(this.props.detailsErrorMessages?.ccaBundleErrorMessage) ? <div className="error-text-color">{this.props.detailsErrorMessages?.ccaBundleErrorMessage}</div> : null}
                            </div>
                    </div>
                    <DropdownComponent
                        title="Integration Type"
                        placeholder="Choose integration type"
                        items={[IntegrationType[IntegrationType.AICC_SNS], IntegrationType[IntegrationType.Deeplink], IntegrationType[IntegrationType.AICC_Manual], IntegrationType[IntegrationType.AICC_HACP]]}
                        widthClass={InputWidthTypes.Small}
                        onValueChanged={this.onIntegrationTypeChange}
                        disabled={this.props.organizations!.FrameworkMode === FrameworkModes.Edit}
                        important={true}
                        errorMessage={this.props.detailsErrorMessages?.integrationTypeErrorMessage}
                        defaultValue={integrationTypeToString(this.props.organizations?.CurrentOrganization?.IntegrationType)}
                    />

                    <DropdownComponent
                        title="Environment"
                        placeholder="Choose environment"
                        items={[EnvironmentType[EnvironmentType.Production], EnvironmentType[EnvironmentType.Stage]]}
                        widthClass={InputWidthTypes.Small}
                        onValueChanged={this.onEnvTypeChange}
                        important={true}
                        disabled={this.props.organizations!.FrameworkMode === FrameworkModes.Edit}
                        errorMessage={this.props.detailsErrorMessages?.envTypeErrorMessage}
                        defaultValue={envTypeToString(this.props.organizations?.CurrentOrganization?.Environment)}
                    />
                </div>
                <div className="csod-inputs-row-container two-columns-container">
                    <div className='csod-inputs-column-container middle-width'>
                        <div className="csod-input-title-text non-selectable">Select Learning Platform *</div>
                        <Select placeholder="Select Learning Platform" myFontSize="20px" isMulti={false}
                                important={true} options={learningPlatofrmList?.map(x => ({ label: x, value: x })).sort((a, b) => a.label.localeCompare(b.label))}
                                value={learningPlatofrmList?.map(x => ({ label: x, value: x })).find(({ value }) => value === this.props.organizations?.CurrentOrganization?.LearningPlatform)}
                                onChange={this.onLearningPlatformNameChange} />
                                {!CodeHelpers.IsStringNullOrEmpty(this.props.detailsErrorMessages?.learningPlatformErrorMessage) ? <div className="error-text-color">{this.props.detailsErrorMessages?.learningPlatformErrorMessage}</div> : null}
                                </div>
                        <TextInputComponent title="Salesforce ID" important={true} widthClass={InputWidthTypes.Middle}
                            defaultValue={this.props.organizations?.CurrentOrganization?.SalesforceId} 
                            errorMessage={this.props.detailsErrorMessages?.salesForceIdErrorMessage}
                            maxlength={500} onValueChanged={this.onSalesForceIdChange} />
                </div>
                {this.state.integrationType != undefined && !this.deepLinkSelectedDisable &&
                    <div className="csod-inputs-row-container two-columns-container">
                        <div className='csod-inputs-column-container middle-width' style={{ marginTop: '0px' }}>
                            <CustomTooltip arrow title="Examples: .*123.* to match any SID containing 123,  123.* for any SID starting with 123 etc.">
                                <div>
                                    <TextInputComponent
                                        title="Tenant Identifier" important={false} widthClass={InputWidthTypes.Middle}
                                        defaultValue={this.props.organizations?.CurrentOrganization?.TenantIdentifier}
                                        errorMessage={this.props.detailsErrorMessages?.tenantIdentifierErrorMessage} maxlength={500}
                                        onValueChanged={this.onTenantIdentifierChange} />
                                </div>
                            </CustomTooltip>
                        </div>
                        <div className='csod-inputs-column-container'>
                            <div className="csod-input-title-text non-selectable">Aicc Urls Input *</div>
                            <CreatableSelect placeholder="Aicc Urls" myFontSize="20px" isMulti={true} important={true}
                                value={this.props.organizations?.CurrentOrganization?.AiccUrl?.map(x => ({ label: x, value: x }))}
                                onChange={this.onAiccUrlChange} />
                            {!CodeHelpers.IsStringNullOrEmpty(this.props.detailsErrorMessages?.aiccUrlErrorMessage) ? <div className="error-text-color">{this.props.detailsErrorMessages?.aiccUrlErrorMessage}</div> : null}
                        </div>
                    </div>}
                <TextInputComponent title="Learning Platform Host URL" important={true} widthClass={InputWidthTypes.Maximum}
                defaultValue={this.props.organizations?.CurrentOrganization?.LearningHostUrl} errorMessage={this.props.detailsErrorMessages?.learningHostUrlErrorMessage}
                maxlength={500} onValueChanged={this.onLearningPlatformHostUrlChange} type="url" />

                <TextInputComponent title="Description" important={true} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.organizations?.CurrentOrganization?.Description}
                    errorMessage={this.props.detailsErrorMessages?.descriptionErrorMessage} maxlength={200} onValueChanged={this.onDescriptionChanged} />
                <div className="csod-inputs-row-container two-columns-container">
                    <div className='csod-inputs-column-container middle-width'>
                         <div className="csod-input-title-text non-selectable">Emails Input *</div>
                            <CreatableSelect placeholder="Organization User Emails" myFontSize="20px" isMulti={true}
                                value={this.props.organizations?.CurrentOrganization?.AddUserEmails?.map(x => ({ label: x, value: x }))}
                                onChange={this.onAddUserEmailsChange} />
                    </div>
                    <div className='csod-inputs-column-container middle-width'>
                        <div className="csod-input-title-text non-selectable">Select Metadata Language </div>
                        <Select placeholder="Select Metadata Language" myFontSize="20px" isMulti={true}
                            important={false} options={this.props.organizations?.MetadataLanguages.map(x => ({ label: this.formatLanguageTag(x), value: x }))}
                            defaultValue={this.props.organizations?.MetadataLanguages.filter(x =>this.props.organizations?.CurrentOrganization?.MetadataLanguages?.includes(x)).map(x=>({label: this.formatLanguageTag(x), value:x}))  }
                            onChange={this.onCcaMetadataLanguageChange} />
                    </div>
                </div>
                <div className="csod-accordion-content corp-div-selector add-padding-corp-setting">
                    <button className="csod-button gray less-rounded-corners" onClick={() => this.OnAddNewCorpSetting()}>Add New Corp Settings</button>
                    {this.props.organizations!.CurrentOrganization!.OrgSettingCollection!.map(i => this.renderCorpSettings(v4(),corpSettingsCount++, corpSettingsCount !== 1))}
                    {!CodeHelpers.IsStringNullOrEmpty(this.props.detailsErrorMessages?.organizationCorpErrorMessage) ? <div className="error-text-color">{this.props.detailsErrorMessages?.organizationCorpErrorMessage}</div> : null}
                </div>

                {this.state.integrationType === IntegrationType.AICC_SNS &&
                    <TextInputComponent title="Subscriber Uri" important={true} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.organizations?.CurrentOrganization?.SubscriberUri}
                        errorMessage={this.props.detailsErrorMessages?.subscriberUriErrorMessage} maxlength={200} onValueChanged={this.onSubscriberUriChanged} type='url' />
                }
                <div className="csod-inputs-column-container">
                    <div className="csod-inputs-row-container">
                        <SwitcherComponent title="Is Active" defaultValue={!(this.props.organizations?.CurrentOrganization?.IsActive === false)} onValueChanged={this.onIsActiveChanged} />
                    </div>
                </div>
                {(this.state.integrationType === IntegrationType.Deeplink || this.state.integrationType === IntegrationType.AICC_HACP) &&
                    <div>
                        <div className="csod-sub-framework-layer">
                            <div className="csod-framework-header non-selectable  margin-top-for-inputs">{OrganizationDetailsConstants.BASIC_AUTHENTICATION_HEADER}</div>
                            <div className="csod-inputs-row-container four-columns-container">
                                <DropdownComponent
                                    title="Authentication Type"
                                    placeholder="Choose authentication type"
                                    items={[AuthenticationType[AuthenticationType.Basic]]}
                                    widthClass={InputWidthTypes.Small}
                                    onValueChanged={this.onAuthTypeChanged}
                                    important={true}
                                    defaultValue={authTypeToString(this.props.organizations?.CurrentOrganization?.Authentication?.AuthType)}
                                />
                            </div>
                            {this.state.authType === AuthenticationType.oAuth2 ? this.renderOauth2Inputs() : null}
                            {this.state.authType === AuthenticationType.Basic ? this.renderBasicAuthInputs() : null}
                        </div>
                    </div>}
                {this.state.integrationType === IntegrationType.Deeplink &&
                    <div>
                        <div className="csod-sub-framework-layer">
                            <div className="csod-framework-header non-selectable">{OrganizationDetailsConstants.SSO_HEADER}</div>
                            {this.renderSsoObject()}
                        </div>
                        <div className="csod-sub-framework-layer">
                            <div className="csod-framework-header non-selectable">{OrganizationDetailsConstants.XAPI_HEADER}</div>
                            {this.renderXapi()}
                        </div>
                    </div>}
            </div>
            {(this.props.organizations!.FrameworkMode === FrameworkModes.Edit && this.renderEmailUpdate())}
        </div>
    }

    
    //    Org XAPI Freeform START
    private renderFreeForm = (key: string, index: number, forSsoConfig: boolean, isHeaderHidden: boolean) => {
        var additionalSettingModel = forSsoConfig ? this.props.organizations?.CurrentOrganization?.Sso?.FreeformFields![index] :
            this.props.organizations?.CurrentOrganization?.XApi?.FreeformFields![index];

        return <div key={key} className="csod-inputs-row-container no-right-margins four-columns-30-20-20-30">
            <TextInputComponent title="Key" isHeaderHidden={isHeaderHidden} additionalClass={"less-margin-top"} defaultValue={additionalSettingModel?.Key} widthClass={InputWidthTypes.Small} onValueChanged={this.GetOnAdditionalSettingKeyChange(index, forSsoConfig ?? false)} />
            {!forSsoConfig && <DropdownComponent title="Type" isHeaderHidden={isHeaderHidden} additionalClass={"less-margin-top"} placeholder="Type" items={Object.values(XapiFreeformType).filter(i => !CodeHelpers.IsStringANumber(i))} defaultValue={additionalSettingModel?.Type} widthClass={InputWidthTypes.Small} onValueChanged={this.GetOnAdditionalSettingTypeChange(index)} />}
            <TextInputComponent title="Value" isHeaderHidden={isHeaderHidden} additionalClass={"less-margin-top"} defaultValue={additionalSettingModel?.Value} widthClass={InputWidthTypes.Small} onValueChanged={this.GetOnAdditionalSettingValueChange(index, forSsoConfig ?? false)} />
            <button className="csod-remove-row-button org-remove-param" onClick={this.OnRemoveFreeFormKV(index, forSsoConfig ?? false)} />
        </div>
    }

    private GetOnAdditionalSettingKeyChange = (index: number, forSsoConfig: boolean) => {
        return (value: string) => {
            if (forSsoConfig)
                this.props.organizations!.CurrentOrganization!.Sso!.FreeformFields![index].Key = value;
            else
                this.props.organizations!.CurrentOrganization!.XApi!.FreeformFields![index].Key = value;
        }
    }

    private GetOnAdditionalSettingTypeChange = (iterator: number) => {
        return (value: string) => {
            this.props.organizations!.CurrentOrganization!.XApi!.FreeformFields![iterator].Type = value as XapiFreeformType;
        }
    }

    private SetCorpSettingKeyChange = (index: number)  => {
        return (key: string) => {
            this.props.organizations!.CurrentOrganization!.OrgSettingCollection![index].Key = key.value;
        }
    }

    private SetCorpSettingValueChange = (index: number)  => {
        return (value: string) => {
            this.props.organizations!.CurrentOrganization!.OrgSettingCollection![index].Value = value;
        }
    }

    private GetOnAdditionalSettingValueChange = (index: number, forSsoConfig: boolean) => {
        return (value: string) => {
            if (forSsoConfig)
                this.props.organizations!.CurrentOrganization!.Sso!.FreeformFields![index].Value = value;
            else
                this.props.organizations!.CurrentOrganization!.XApi!.FreeformFields![index].Value = value;
        }
    }
    private OnAddNewCorpSetting = () => {
        if(!this.props.organizations!.CurrentOrganization!.OrgSettingCollection) 
        {
            this.props.organizations!.CurrentOrganization!.OrgSettingCollection= [];
        }
        this.props.organizations?.CurrentOrganization?.OrgSettingCollection!.push(new KeyValueInput());
        this.forceUpdate();
    }
    private OnRemoveCorpSetting = (index: number) => {
        return () => {
            this.props.organizations?.CurrentOrganization?.OrgSettingCollection!.splice(index, 1);
        this.forceUpdate();
        }
    }
    
    private OnAddNewFreeFormKV = (isSsoSpecific: boolean) => {
        if (isSsoSpecific)
            this.props.organizations?.CurrentOrganization?.Sso?.FreeformFields!.push(new KeyValueInput());
        else{
            this.CreateXapiIfNotCreated();
            this.props.organizations?.CurrentOrganization?.XApi?.FreeformFields!.push(new KeyTypeValueInput());
        }
        this.forceUpdate();
    }

    private OnRemoveFreeFormKV = (index: number, isSsoSpecific: boolean) => {
        return () => {
            if (isSsoSpecific)
                this.props.organizations?.CurrentOrganization?.Sso?.FreeformFields!.splice(index, 1);
            else
                this.props.organizations?.CurrentOrganization?.XApi?.FreeformFields!.splice(index, 1);
            this.forceUpdate();
        }
    }
    //    Org XAPI Freeform END

    private renderIdpSso = () => {
        return  <div>
        <div className="csod-inputs-row-container two-columns-container">
            <TextInputComponent title="Request Destination" important={false} errorMessage={this.props.detailsErrorMessages?.requestDestinationErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.RequestDestination} maxlength={500} onValueChanged={this.onSsoReqDestinationChange} />
            <TextInputComponent title="Audience" important={false} errorMessage={this.props.detailsErrorMessages?.ssoAudienceErrorMessage} widthClass={InputWidthTypes.Middle}
              value={this.props.organizations?.CurrentOrganization?.Sso?.Audience} defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.Audience} maxlength={500} onValueChanged={this.onSsoAudienceChange} isAbleToCopy={true} />
        </div>
        <div className="csod-inputs-row-container two-columns-container">
            <TextInputComponent title="Response Issuer" important={false} errorMessage={this.props.detailsErrorMessages?.ssoIssuerErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.ResponseIssuer} maxlength={500} onValueChanged={this.onSsoResIssuerChange} />
           <LabelComponent title="SP Certificate" value={this.props.organizations?.CurrentOrganization?.Sso?.SpCertificate }
             isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
        </div>
        <div className="csod-inputs-row-container two-columns-container">
            <LargeTextboxComponent title="Idp Certification" important={false} errorMessage={this.props.detailsErrorMessages?.ssoSamlRequestEncryptionErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.IdpCertificate} onValueChanged={this.onSsoIDPCertChange} />
            
        </div>
    </div>
    }


    private renderSpSso = () => {
        return  <div>
        <div className="csod-inputs-row-container two-columns-container">
        <TextInputComponent title="Request Destination" important={false} errorMessage={this.props.detailsErrorMessages?.requestDestinationErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.RequestDestination} maxlength={500} onValueChanged={this.onSsoReqDestinationChange} />
            <LabelComponent title="Assertion Consumer Url" value={this.props.organizations?.CurrentOrganization?.Sso?.AssertionConsumerServiceUrl }
             isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
        </div>
        <div className="csod-inputs-row-container two-columns-container">
            <TextInputComponent title="Response Issuer" important={false} errorMessage={this.props.detailsErrorMessages?.ssoIssuerErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.ResponseIssuer} maxlength={500} onValueChanged={this.onSsoResIssuerChange} />
            {/* <TextInputComponent title="Response Destination" important={false} errorMessage={this.props.detailsErrorMessages?.ssoDestinationErrorMessage} widthClass={InputWidthTypes.Middle}
                 defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.ResponseDestination} maxlength={500} onValueChanged={this.onSsoResDestinationChange} /> */}
            <TextInputComponent title="Audience" important={false} errorMessage={this.props.detailsErrorMessages?.ssoAudienceErrorMessage} widthClass={InputWidthTypes.Middle}
                defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.Audience} maxlength={500}  isAbleToCopy={true} onValueChanged={this.onSsoAudienceChange}  />
        </div>
        <div className="csod-inputs-row-container two-columns-container">
            <LargeTextboxComponent title="Idp Certification" important={false} errorMessage={this.props.detailsErrorMessages?.ssoSamlRequestEncryptionErrorMessage} widthClass={InputWidthTypes.Middle}
               defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.IdpCertificate} onValueChanged={this.onSsoIDPCertChange} />
            <TextInputComponent title="Request Issuer" important={false} errorMessage={this.props.detailsErrorMessages?.ssoIssuerErrorMessage} widthClass={InputWidthTypes.Middle}
               defaultValue={this.props.organizations?.CurrentOrganization?.Sso?.RequestIssuer} maxlength={500} onValueChanged={this.onSsoReqIssuerChange} isAbleToCopy={true} />
        </div>



            {/* NOT IMPLEMENTED  YET. CONT-30568*/}
            {/* <div className="csod-inputs-row-container two-columns-container">
            <SwitcherComponent title="Saml Request Encryption" defaultValue={!(this.props.organizations?.CurrentOrganization?.Sso?.IsSpEncrypted === false)} onValueChanged={this.onIsSpEncryptedChange} additionalClass={"middle-width"} />
            {this.props.organizations!.CurrentOrganization!.Sso?.IsSpEncrypted ? 
            <LabelComponent title="SP Certificate" value={this.props.organizations?.CurrentOrganization?.Sso?.SpCertificate }
            isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
             : null}
        </div> */}

    </div>
    }
    private renderCorpSettings = (key: string, index: number, isHeaderHidden: boolean) => {
        var corpValue =  this.props.organizations?.CurrentOrganization?.OrgSettingCollection![index];
        return <div key={key} className="csod-inputs-row-container no-right-margins four-columns-30-20-20-30">
            <Select title="Corp Settings" placeholder="Select Corp Name from list"  defaultValue={{value:corpValue?.Key, label: corpValue?.Key}} onChange={this.SetCorpSettingKeyChange(index)} myFontSize="20px" className='csod-inputs-column-container corpname-selector' important = {false} options={corpNameDetails.map(x => ({ label: x, value: x }))}/>
            <TextInputComponent  isHeaderHidden={true} additionalClass={"less-margin-top"} defaultValue={corpValue?.Value}  widthClass={InputWidthTypes.Small} onValueChanged={this.SetCorpSettingValueChange(index)} />
            <button className="csod-remove-row-button-corp org-remove-param"  onClick={this.OnRemoveCorpSetting(index)} />
        </div>
    }

    private renderSsoObject = () => { 
        var additionSettingsCount = 0;
        this.CreateSsoIfNotCreated();

        if (!this.props.organizations!.CurrentOrganization!.Sso!.FreeformFields)
            this.props.organizations!.CurrentOrganization!.Sso!.FreeformFields = [];
        
            let ssoType;
            
            if (this.state.ssoType == 0)
                ssoType = this.renderIdpSso();
            else if ((this.state.ssoType == 1))
                    ssoType = this.renderSpSso();

        return <div>
            <div className="csod-inputs-row-container two-columns-container">

                <DropdownComponent
                    title="Sso Type" 
                    placeholder="Choose SSO type"
                     items={[SsoType[SsoType.SP]+" Initiated"]}    //IDP not implemnted yet                     
                    widthClass={InputWidthTypes.Middle}
                    onValueChanged={this.onSsoTypeChange}
                    important={true}
                    errorMessage={this.props.detailsErrorMessages?.ssoTypeErrorMessage}
                    defaultValue={ssoTypeToString(this.props.organizations?.CurrentOrganization?.Sso?.Type) ?? "IDP"}
                />

            </div>
            {ssoType}
            <div className={`csod-accordion-framework-layer erases-at-disabling`} >
            <div className="csod-accordion-content">
                <button className="csod-button gray less-rounded-corners" onClick={() => this.OnAddNewFreeFormKV(true)}>Add New SSO Freeform Parameters</button>
                {this.props.organizations!.CurrentOrganization!.Sso!.FreeformFields!.map(i => this.renderFreeForm(v4(), additionSettingsCount++, true, additionSettingsCount !== 1))}
            </div>
        </div>
        </div>
    }

    private renderXapi = () => {
        var additionSettingsCount = 0;
        
        return <div>
            <div className="csod-inputs-row-container four-columns-container">
                <DropdownComponent
                    title="Xapi Authentication Type"
                    placeholder="Choose authentication type"
                    items={[AuthenticationType[AuthenticationType.oAuth2]]}
                    widthClass={InputWidthTypes.Small}
                    onValueChanged={this.onXapiAuthTypeChanged}
                    errorMessage={this.props.detailsErrorMessages?.xapiAuthTypeErrorMessage}
                    important={true}
                    defaultValue={authTypeToString(this.props.organizations?.CurrentOrganization?.XApi?.AuthType)}
                />
            </div>
            <TextInputComponent title="Authentication Token Url" type="url" important={true} errorMessage={this.props.detailsErrorMessages?.xapiAuthTokenUrlErrorMessage}
                widthClass={InputWidthTypes.Maximum} defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.AuthenticationTokenUrl} maxlength={500} onValueChanged={this.onXapiAuthTokenUrlChanged} />

            <TextInputComponent title="Activity Statement Url" type="url" important={true} errorMessage={this.props.detailsErrorMessages?.xapiActivityStatementUrlErrorMessage}
                widthClass={InputWidthTypes.Maximum} defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.XApiEndpoint} maxlength={500} onValueChanged={this.onXapiActivityStatementUrlChanged} />

            <TextInputComponent title="Account Homepage" type="url" important={true} errorMessage={this.props.detailsErrorMessages?.xapiAccountHomepageErrorMessage}
                widthClass={InputWidthTypes.Maximum} defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.XApiAccountHomepage} maxlength={500} onValueChanged={this.onXapiAccountHomepageChanged} />

            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Client ID" important={true} errorMessage={this.props.detailsErrorMessages?.xapiClientIdErrorMessage} widthClass={InputWidthTypes.Middle}
                    defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.ClientId} maxlength={500} onValueChanged={this.onXapiClientIdChanged} />
                <TextInputComponent title="Client Secret" important={true} errorMessage={this.props.detailsErrorMessages?.xapiClientSecretErrorMessage} widthClass={InputWidthTypes.Middle}
                    defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.ClientSecret} maxlength={500} onValueChanged={this.onXapiClientSecretChanged} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Grant Type" important={true} errorMessage={this.props.detailsErrorMessages?.xapiGrantTypeErrorMessage} widthClass={InputWidthTypes.Middle}
                    defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.GrantType} maxlength={500} onValueChanged={this.onXapiGrantTypeChanged} />
                <TextInputComponent title="Scope" important={true} errorMessage={this.props.detailsErrorMessages?.xapiScopeErrorMessage} widthClass={InputWidthTypes.Middle}
                    defaultValue={this.props.organizations?.CurrentOrganization?.XApi?.Scope} maxlength={500} onValueChanged={this.onXapiScopeChanged} />
            </div>
            <div className={`csod-accordion-framework-layer erases-at-disabling`} >
                <div className="csod-accordion-content">
                    <button className="csod-button gray less-rounded-corners" onClick={() => this.OnAddNewFreeFormKV(false)}>Add New XAPI Freeform Parameters</button>
                    {this.props.organizations!.CurrentOrganization!.XApi && this.props.organizations!.CurrentOrganization!.XApi!.FreeformFields!.map(i => this.renderFreeForm(v4(), additionSettingsCount++, false, additionSettingsCount !== 1))}
                </div>
            </div>
        </div>
    }

    private renderOauth2Inputs = () => {
        return <div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent type="username" title="Client ID" important={true} errorMessage={this.props.detailsErrorMessages?.authenticationClientIdErrorMessage} widthClass={InputWidthTypes.Middle} defaultValue={this.props.organizations?.CurrentOrganization?.Authentication?.ClientId} maxlength={500} onValueChanged={this.onClientIdChanged} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent type="password" title="Client Secret Key" important={true} errorMessage={this.props.detailsErrorMessages?.authenticationClientSecretErrorMessage} widthClass={InputWidthTypes.Middle} defaultValue={this.props.organizations?.CurrentOrganization?.Authentication?.ClientSecret} maxlength={500} onValueChanged={this.onClientSecretKeyChanged} />
            </div>
        </div>
    }

    private renderEmailUpdate = () => {
        return <div>
                {
                    (this.props.organizations.CurrentOrganization.OrganizationModifications?.sort((a,b) => new Date(b.OrganizationModified).getTime() - new Date(a.OrganizationModified).getTime()))?.map(function(element){
                        return <div className="csod-inputs-row-container two-columns-container changeByUserStyle">
                            Last modified by:  {element.LastModifiedBy} - {new Date(element.OrganizationModified).toLocaleString()}
                        </div>;
                    })
                }
            </div>
    }

    private renderBasicAuthInputs = () => {
        return <div>
            <div className="csod-inputs-row-container two-columns-container">
                <LabelComponent title="Username" value={this.props.organizations?.CurrentOrganization?.Authentication?.Username}
                    isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <LabelComponent title="Password" value={this.props.organizations?.CurrentOrganization?.Authentication?.Password}
                    isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
            </div>
        </div>
    }


    private onIsSpEncryptedChange = (value: boolean) => {
        this.props.organizations!.CurrentOrganization!.Sso!.IsSpEncrypted = value;
        this.setState({ isSpEncrypted: value })
    }
    private onIsActiveChanged = (value: boolean) => {
        this.props.organizations!.CurrentOrganization!.IsActive = value;
    }

    private onDescriptionChanged = (value: string) => {
        this.props.organizations!.CurrentOrganization!.Description = value;
    }

    private onSubscriberUriChanged = (value: string) => {
        this.props.organizations!.CurrentOrganization!.SubscriberUri = value;
    }

    private onOrganizationNameChanged = (value: string) => {
        this.props.organizations!.CurrentOrganization!.Name = value;
    }

    private onEnvTypeChange = (value: string) => {
        let env: EnvironmentType | undefined = stringToEnvType(value);
        this.props.organizations!.CurrentOrganization!.Environment = env;
        this.setState({ envType: env });
    }

    private onIntegrationTypeChange = (value: string) => {
        let type: IntegrationType | undefined = stringToIntegrationType(value);
        this.deepLinkSelectedDisable = false;
        if (type == IntegrationType.Deeplink) {
            this.deepLinkSelectedDisable = true;
            this.props.organizations!.CurrentOrganization!.AiccUrl = ['www.csod-content.com'];
            this.props.organizations!.CurrentOrganization!.TenantIdentifier = this.props.organizations!.CurrentOrganization!.Id;
        }
        else {
            this.props.organizations!.CurrentOrganization!.Sso = undefined;
            this.props.organizations!.CurrentOrganization!.XApi = undefined;
            this.props.organizations!.CurrentOrganization!.AiccUrl = [];
            this.props.organizations!.CurrentOrganization!.TenantIdentifier = '';
        }

        this.props.organizations!.CurrentOrganization!.IntegrationType = type;
        this.setState({ integrationType: type })
    }
    private onAiccUrlChange = (value: any) => {
        this.props.organizations!.CurrentOrganization!.AiccUrl = value.map((x) => {
            return x.value;
        });
        this.setState({});
    }

    private onAddUserEmailsChange = (value: any) => {
        value = value.map(str =>  ({
            value : str.value.replace(" ",""),
            label: str.label.replace(" ","")
        }));
        this.props.organizations!.CurrentOrganization!.AddUserEmails = value.map((x) => {
            return x.value;
        });
        this.setState({});
    }

    private onLearningPlatformHostUrlChange = (value: string) => {
        this.props.organizations!.CurrentOrganization!.LearningHostUrl = value;
    }

    private onLearningPlatformNameChange = (item: any) => {
        this.props.organizations!.CurrentOrganization!.LearningPlatform = item.value;
    }
    
    private onSalesForceIdChange = (value: string) => {
        this.props.organizations!.CurrentOrganization!.SalesforceId = value;
    }
    private onCcaBundleChanged = (value: any) => {
        this.props.organizations!.CurrentOrganization!.OrgBundle = value.map((x) => {
            return { Id: x.value, IsActive: true };
        });
    }
    private onCcaMetadataLanguageChange = (value: any) => {
        this.props.organizations!.CurrentOrganization!.MetadataLanguages = value.map(x=> x.value)
    }

    private onTenantIdentifierChange = (value: string) => {
        this.props.organizations!.CurrentOrganization!.TenantIdentifier = value;
    }

    private onXapiAuthTokenUrlChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.AuthenticationTokenUrl = value;
    }

    private onXapiClientIdChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.ClientId = value;
    }
    private onXapiClientSecretChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.ClientSecret = value;
    }

    private onXapiScopeChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.Scope = value;
    }

    private onXapiGrantTypeChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.GrantType = value;
    }

    private onXapiActivityStatementUrlChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.XApiEndpoint = value;
    }

    private onXapiAccountHomepageChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        this.props.organizations!.CurrentOrganization!.XApi!.XApiAccountHomepage = value;
    }
    /*XAPI end*/

    /*Auth Start */
    private onClientIdChanged = (value: string) => {
        this.props.organizations!.CurrentOrganization!.Authentication!.ClientId! = value;
    }

    private onClientSecretKeyChanged = (value: string) => {
        this.props.organizations!.CurrentOrganization!.Authentication!.ClientSecret! = value;
    }

    private onAuthTypeChanged = (value: string) => {
        this.CreateAuthIfNotCreated();
        let authType = stringToAuthType(value);
        if (authType != null) {
            this.props.organizations!.CurrentOrganization!.Authentication!.AuthType = authType;
            this.setState({ authType: authType });
        }
    }

    private onXapiAuthTypeChanged = (value: string) => {
        this.CreateXapiIfNotCreated();
        let authType = stringToAuthType(value);
        if (authType != null) {
            this.props.organizations!.CurrentOrganization!.XApi!.AuthType = authType;
        }
    }

    /*Auth End*/

    /*Sso Start*/
    private onSsoTypeChange = (value: string) => {
        if (value.toLocaleLowerCase().includes("initiated"))
            value = value.split(' ')[0]

        this.CreateSsoIfNotCreated();
        let type: SsoType | undefined = stringToSsoType(value);
        this.props.organizations!.CurrentOrganization!.Sso!.Type = type;
        this.setState({ ssoType: type });
    }

  
    private onSsoIDPCertChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.IdpCertificate = value;
    }

    private onSsoReqIssuerChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.RequestIssuer = value; 
    }
    private onSsoResIssuerChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.ResponseIssuer = value;
    }

    private onSpCertificateChange = (value: string) =>{
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.SpCertificate = value;
    } 

    private onSsoReqDestinationChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.RequestDestination = value;
    }


    private onSsoAssertionConsumerServiceUrlChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.AssertionConsumerServiceUrl = value;
    }

    private onSsoAudienceChange = (value: string) => {
        this.CreateSsoIfNotCreated();
        this.props.organizations!.CurrentOrganization!.Sso!.Audience = value;
    }

    private CreateSsoIfNotCreated() {
        if (!this.props.organizations!.CurrentOrganization!.Sso) {
            this.props.organizations!.CurrentOrganization!.Sso = new SSOObject("", undefined, "", "", "https://www.csod-content.com/sso/api/process-saml-response?OrganizationId="+this.props.organizations!.CurrentOrganization!.Id, "", "", "", "");
            this.props.organizations!.CurrentOrganization!.Sso.RequestIssuer = "csod-content.csod.com";
            this.props.organizations!.CurrentOrganization!.Sso.Audience = "csod-content.csod.com";
        }
    }

    private CreateAuthIfNotCreated() {
        if (!this.props.organizations!.CurrentOrganization!.Authentication) this.props.organizations!.CurrentOrganization!.Authentication = new AuthenticationObject(AuthenticationType.None, "", "", "", "");
    }

    private CreateXapiIfNotCreated() {
        if (!this.props.organizations!.CurrentOrganization!.XApi) {
            this.props.organizations!.CurrentOrganization!.XApi = new XAPIProcessObject("", "", "", "", "", "");
            this.props.organizations!.CurrentOrganization!.XApi.FreeformFields = [];
        }
    }
    
    /*Sso End*/
}